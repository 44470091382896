import React, {useState} from "react"
import {graphql, Link} from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout";


import {Card, Col, Row} from "antd";
import SEO from "../components/seo";
import {selectionFilter} from "../components/styles";

const IndexPage = ({data}) => {
    const [cardHover, setCardHover] = useState("");

    return (
        <Layout>
            <SEO title="Проекты"/>

            <Row gutter={[24 + 16, 48]}>
                {data.projects.edges.map(elem => {
                    let frontmatter = elem.node.childMarkdownRemark.frontmatter;

                    // if (node.parent.name !== "post") {
                    //     return <Fragment key={node.id} />
                    // }

                    return <Col md={8} sm={24} key={frontmatter.path} style={{width: "100%"}}>
                        <Link to={frontmatter.path}>
                            <Card
                                className="project-card"
                                bordered={false}
                                style={{width: "100%"}}
                                bodyStyle={{paddingTop: "5px", paddingLeft: "0px"}}
                                onMouseEnter={() => setCardHover(frontmatter.path)}
                                onMouseLeave={() => setCardHover("")}
                                cover={frontmatter.featuredImage === null ? <div>MISSED {frontmatter.path}</div> : <Img fluid={frontmatter.featuredImage.childImageSharp.fluid}
                                            style={cardHover === frontmatter.path ? {
                                                filter: selectionFilter,
                                            } : {}}
                                            objectFit="contain"
                                            className="project-img"
                                />}
                            >
                                <div className="project-text">
                                    <div style={{fontSize: "12px"}}>{frontmatter.title}</div>
                                    <div style={{fontSize: "12px"}}>{frontmatter.city} | {frontmatter.year}</div>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                })}
            </Row>
        </Layout>
    );
};

export default IndexPage

export const query = graphql`
    query {
        projects: allFile(filter: {extension: {regex: "/(md)/"}, sourceInstanceName: {eq: "projects"}, name: {eq: "post"}}, sort: {order: DESC, fields: [childMarkdownRemark___frontmatter___date]}) {
            edges {
                node {
                    name
                    sourceInstanceName
                    childMarkdownRemark {
                        frontmatter {
                            path
                            title
                            city
                            date
                            year: date(formatString: "YYYY")
                            featuredImage {
                                childImageSharp {
                                    fluid(maxWidth: 1440, maxHeight: 960, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
